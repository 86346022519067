import axios from "axios";
import handleExpiredSession from "../../utils/api/handleExpiredSession";

const instance = {
  local: false,
  hml: true,
  axios: {},
};

let vsBaseUrl = instance.local
  ? "http://host.docker.internal:8010"
  : instance.hml
  ? "https://hfssiaq3qi.execute-api.us-east-1.amazonaws.com/hml-stage"
  : "https://hfssiaq3qi.execute-api.us-east-1.amazonaws.com/prd_v2_stage";  

instance.axios = axios.create({
  baseURL: vsBaseUrl,
  responseType: "json",
});

axios.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";

instance.axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.request.responseURL.includes(vsBaseUrl) &&
      error.response?.status === 401 &&
      !error.config.headers["X-No-Redirect-401"]
    ) {
      handleExpiredSession();
    }

    return Promise.reject(error);
  }
);

export default instance;
